import React from "react";
import Layout from "../components/Layout/Layout";
import { NavLink } from "react-router-dom"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../styles/About.css";

const About = () => {
  return (
    <Layout title={"About Us - Ecommerce App"}>
      <div className="about-container">
        <h1 className="about-title">About Us</h1>
        <div className="about-content">
          <div className="about-section">
            <div className="about-card">
              <h3 className="item-title">ડિજિટલ બિઝનેસ પ્લાન (DBP)</h3>
              {/* Additional content */}
            </div>
            {/* Repeat similar cards for each section */}
          </div>
          <div className="about-footer">
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ડિજિટલ બિઝનેસ પ્લાન (DBP) એ એક અગ્રણી નેટવર્કિંગ કંપની છે, જે વ્યાવસાયિક અને વ્યક્તિગત સ્તરે લોકો વચ્ચે કનેક્શન બનાવીને તેમને વધારાની આવક કમાવા માટેની તક પ્રદાન કરે છે. DBP એ એવા દરેક વ્યક્તિ માટે એક નવું માર્ગ દર્શાવ્યું છે, જે વિદ્યાર્થીઓ, કર્મચારીઓ, ગૃહિણીઓ, અથવા વ્યવસાયિક લોકો હોય, તમામને આ યોજનામાં જોડાઈને નાણાકીય સ્વતંત્રતા પ્રાપ્ત કરવાની તક મળી શકે છે.
            </p><br></br>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> DBP નું મુખ્ય ધ્યેય છે, નેટવર્કિંગના માધ્યમથી લોકો વચ્ચે મજબૂત બિઝનેસ સંબંધો કેળવવા અને તેમના જીવનમાં વધારાની આવકના સ્રોતો ઉદભવિત કરવા. સરળ અને પ્રદર્શિક વ્યવસાય મોડલ, જેમાં લોકો તેમના મિત્રોને, પરિવારજનોને, અથવા અન્ય સંપર્કોને જોડીને નફો કમાવી શકે છે, DBP ને દરેક માટે એક આકર્ષક વિકલ્પ બનાવે છે.
            </p><br></br>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> DBP દરેક સભ્ય માટે સુરક્ષિત અને પારદર્શક વ્યવસાયનો અનુભવ સૃજવા માટે પ્રતિબદ્ધ છે. અમારું પ્લેટફોર્મ 100% કાયદેસર છે અને તમામ પેમેન્ટ અને ટ્રાન્ઝેક્શનDBP ની ઓફિશિયલ વેબસાઇટ મારફતે જ થાય છે. અમારી નીતિ છે કે કોઈપણ વ્યક્તિના સારા નેટવર્કને ઓળખ આપવી અને તેમને તેમના યોગદાન માટે પ્રમાણિત રીતે પુરસ્કૃત કરવું.
            </p><br></br>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> DBP ના સભ્યો માત્ર નફો જ નહીં પણ બિઝનેસ કિટ અને પ્રમાણપત્ર પણ મેળવે છે, જે તેમને "મુલ્યવાન નેટવર્કર" તરીકે ઓળખ આપે છે.
            </p><br></br>
             <p className="item-description">
                <FontAwesomeIcon icon={faArrowRight} /> DBP એ એક અગ્રણી નેટવર્કિંગ કંપની છે...                                                                                                    
              </p><br></br>
            <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>અમારું મિશન :-</b></h5>
            <p className="abcd">
            દરેક વ્યક્તિને નેટવર્કિંગની શક્તિ સાથે નાણાકીય પ્રગતિ અને સફળતાની શક્યતાઓ પ્રદાન કરવી.
            </p><br></br>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>અમારું વિઝન :-</b></h5>
            <p className="abcd">
            એક એવું મજબૂત નેટવર્ક બનાવવા જે દરેક વ્યક્તિ માટે નફાકારક અને વ્યવસાયિક સ્તરે વૃદ્ધિ કરે.
            </p><br></br>
          </div>
          <p className="item-description">
                <FontAwesomeIcon icon={faArrowRight} /> DBP સાથે જોડાઈને, તમે પણ આ નેટવર્કિંગ રિવોલ્યુશનનો ભાગ બની શકો છો અને નાણાકીય સ્વતંત્રતા તરફ એક પગલું આગળ વધી શકો છો.
              </p>
              <div className="button-container">
          <NavLink to="/register" className="cta-button">Join Us Now</NavLink>
        </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
