import React from "react";
import Layout from "../components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../styles/Service.css";

const Service = () => {
  return (
    <Layout title={"Service as - Ecommerce App"}>
      <div className="service-container">
        <h1 className="service-title">Service Details</h1>
        <div className="service-content">
          <div className="service-item">
            <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>પાત્રતા :-</b></h5>
            <p className="abcd">
            ભાગ લેનારાઓની ઉંમર ઓછામાં ઓછા 18 વર્ષ હોવી જોઈએ અને તેમને બેઝિક કમ્પ્યુટર અને ઇન્ટરનેટની જાણકારી હોવી જોઈએ.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>ફી પરત :-</b></h5>
            <p className="abcd">
            ₹1100 ની જોડાવાની ફી 6 મહિના માં કોઈપણ પ્રકારની કામગીરી નહિ કરે તો પરત કરવામાં આવશે અને મેમ્બરશિપ રદ થશે.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>એકાઉન્ટ સુરક્ષા :-</b></h5>
            <p className="abcd">
            સભ્યો તેમના એકાઉન્ટ માહિતીની ગુપ્તતા જાળવવા માટે જવાબદાર છે.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>પ્રતિબંધિત પ્રવૃત્તિઓ :- </b></h5>
            <p className="abcd">
            સભ્યો કોઈ પણ છેતરપિંડી પ્રવૃત્તિઓ, સ્પેમ, અથવા કોઇ પણ અનૈતિક પ્રથાઓમાં સંકળાશે નહિ.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>રદ કરવાની શરતો :-</b></h5>
            <p className="abcd">
            શરતોના ઉલ્લંઘનથી સભ્ય રદબાતલ કરી શકાય છે, જે અગાઉ સૂચના આપ્યા વિના અથવા રિફંડ વગર થઈ શકે છે.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>આવકનો ઉલ્લેખ :-</b></h5>
            <p className="abcd">
            આવકની કોઇ ગેરંટી નથી અને તે વ્યક્તિગત પ્રયાસ અને બજાર પરિસ્થિતિઓ પર આધાર રાખે છે.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>ફેરફાર :-</b></h5>
            <p className="abcd">
            અમે શરતો અને નિયમો કોઈ પણ સમયે બદલવાનો અધિકાર રાખીએ છીએ. સભ્યોને કોઇ પણ ફેરફાર સૂચના ઇમેઇલ દ્વારા આપવામાં આવશે.
            </p>
          </div>
          <div className="plann-item">
            <h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /><b>કાયદાકીય શાસન :-</b></h5>
            <p className="abcd">
            આ શરતો [તમારા દેશ/રાજ્ય]ના કાયદાઓ મુજબ શાસિત છે અને તેની સાથે અન્વય કરે. એકવાર મેમ્બર બન્યા પછી બીજી વાર મેમ્બર બની શકાશે નહિ.
            </p>
          </div>
          {/* <div className="plann-item">
            <p className="abd"><h5 className="item-description"><FontAwesomeIcon icon={faArrowRight} /></h5>
            ડિજિટલ બિઝનેસ પ્લાન માં આઉટ ઓફ ગુજરાત માંથી ઓનલાઈન પ્લાન લઇ શકશે. આ પ્લાન માં જોડાવા માટે તમારે તમારું ઈ-મેલ આઈડી મોબાઈલ નંબર અને આધારકાર્ડ અને પાસપોર્ટ સાઈઝ ફોટો એટલા ડોક્યુમેન્ટ થી  જોઈનીંગ કરશો. પેમેન્ટ ફરજીયાત @1100/- ઓનલાઈન કરવાનું રેહશે અને એમાં ઓન્લી રેફેરેંસ કોડ નાખવો ફરજીયાત રહેશે.તેજ માન્ય ગણાશે.અને આમાંથી રેફેરેંસ થી જ ઈન્ક્મ જનરેટ થશે.તેની નોંધ લેવી. 
તમે જોબ કરતા કરતા પણ ઈનકમ કમાઈ શકશો.ઘરે બેઠા બેઠા પણ ઇનકમ કમાઈ શકશો. બિઝનેસ કરતા કરતા પણ ઇનકમ કમાઈ શકશો.
            </p>
          </div> */}
        
          <br></br>
            <h2 className="item-title">Terms & Conditions</h2><br></br>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જોઈનીંગ ના સમયે ૧૧૦૦/- રૂપિયા ફરજીયાત ભરવાના રહેશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ૧૧ મેમ્બર ને રેફરન્સ થી ફરજીયાત જોઈન કરવા ના રહેશે (૬૦ દિવસમાં).
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જે વ્યક્તિના કનફોર્મ ૧૧ રેફરન્સ થાય ત્યાર બાદ જ પેમેન્ટ આપવા માં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ૧૧ મેમ્બર થયા બાદ કંપની તરફથી કંપની કીટ આપવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> કનફર્મ મેમ્બરને સારા પરફોર્મન્સ પર એવોર્ડ આપી સન્માનીત કરવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> તમામ મેમ્બરે 100% એકટીવીટી બતાવવી તથા કરવા ની રહેશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> મેમ્બરે દરેક મીટિંગમાં ફરજીયાત હાજરી આપવાની રહેશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> એકટીવ મેમ્બરને લીડરશીપ આપવામાં આવશે અને કંપની સાથ અને સહકાર આપશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> આમા ઓન્લી રેફરન્સ થી જ ઇન્કમ જનરેટ થશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જોઈનીંગ કરેલા મેમ્બરે પોતાનો રેફરન્સ કોડ ફરજીયાત નાખવાનો રહેશે, તેજ માન્ય ગણવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જે મેમ્બર ૧૧ રેફરન્સ પુરા કરી શકે તેમ હોય, તો જ તેમને કંપનીમાં જોડાવું.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> 6 મહિના માં એક પણ રેફરન્સ કોડ નો નાખે તો, તેને ફી પરત કરવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જે મેમ્બર એક ટાસ્ક પૂરો કરે, ત્યારબાદ જ એને બીજો ટાસ્ક આપવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ૧૧૦૦ રૂપિયા મેમ્બરે એક જ વાર ભરવાના રહેશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જે મેમ્બર રેફરન્સ આપે, એમણે કંપનીને અગાવ જાણ કરવાનો રહેશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> એક ટાસ્ક પૂરો થયા પછી જ પેમેન્ટ આપવા માં આવશે.જે મેમ્બર એક ટાસ્ક પૂરો કરે ત્યારબાદ જ એને બીજો ટાસ્ક આપવામાઆવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> જોઈનીંગ થયા પછી એક બીજા સાથે હળીમળી ને રહેવું કોઈ પણજાતની અસભ્યતા ચલાવવામા આવશે નહીં , જેની ખાસનોંધ લેવી અને તેમ છતા પણ કોઈ પણ અસભ્ય વ્યવહાર કરશે તો એ બંને વ્યક્તિની મેમ્બરશીપ રદ કરવામા આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> કંપની ના જે નીતી નીયમ હશે એજે કંપની મા વહીવટ કરતા હશે તે ફેર બદલ કરી શકશે. તેમા કોઈ મેમ્બરનો અસભ્ય વ્યવહાર ચાલશે નહી એની ખાસ નોંધલેવી.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> આ નિયમો નું તમામ મેમ્બર ચુસ્ત પણે પાલન કરવા નું રહેશે. જે કોઈ મેમ્બરઆ નિયમો નું પાલન નહીં કરે એ મેમ્બર ને કોઈ પણ જાત ની દલીલ સ્વીકાર્યા વગર ગ્રુપ માં થી બાકાત કરવામાં આવશે. જેની નોંધ ગ્રુપ ના તમામે તમામ મેમ્બરે લેવી.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ઓનલાઈન પેમેન્ટ જ માન્ય ગણાશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} />  મહિના માં એક વાર મીટિંગ રહશે જેમા હાજરી આપવી.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} />  કંપની ના મોબાઈલ નંબર પર સવારે ૧૦ થી ૬ વાગયા સુધી માં કોન્ટેક્ટ કરી શકશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> Only 1 Year Plan પછી રીન્યુ કરવા નો રેહશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} />જોઈનીંગ મેમ્બર નું પ્રોફિટ 1 થી 10 તારીખે આપવામાં આવશે પેમેન્ટ ઓનલાઈન અથવા ચેક દ્વારા આપવા માં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> એક વર્ષ માં જેટલા મેમ્બર બનાવેલા હશે અને પ્લાન રિન્યૂ થશે તો કમિશન બીજા વર્ષે આપવામાં આવશે અને જેટલા જોઈનીંગ મેમ્બર રિન્યૂ થશે તેમનું જ કમિશન આપવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ડિજિટલ બિઝનેસ પ્લાન માં જોડાય ગયા પછી કોઈ પણ સંજોગો માં મેમ્બરશિપ ફીસ પરત મળશે નહિ. તેમ છતાં જો તમારે મેમ્બરશિપ કૅન્સલ કરવી હોય તો પેનલટી રૂપે ૫૦૦ રૂપિયા આપવા ના રહેશે. જે તમામ જોઈન થયેલા મેમ્બેરો એ ધ્યાન આપવું
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ડિજિટલ બિઝનેસ પ્લાન ના ત્રણ ટાસ્ક Complete કરશે તેને તરત 24 TO 48 કલાક માં પેમેન્ટ આપવામાં આવશે.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> ડિજિટલ બિઝનેસ પ્લાન માં જો કોઈ મેમ્બર બીજી વાર જોઈનીંગ થવાની કોશિશ કરશે તો તેનું એકાઉન્ટ રદ કરવા માં આવશે અને પેમેન્ટ રીટર્ન આપવામાં આવશે નહિ.
            </p>
            <p className="item-description">
              <FontAwesomeIcon icon={faArrowRight} /> અમારા બારકોડ સિવાય અન્ય કોઈ પણ બારકોડમાં પેમેન્ટ કરવું નહિ, અને કરશો તો અમારી જવાબદારી રહેશે નહિ.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service;


