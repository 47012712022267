import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/Plan.css";

const Plan = () => {
  return (
    <Layout title={"Plan as - Ecommerce App"}>
      <div className="plan-container">
        <h1 className="plan-title">🌟 Digital Business Plan 🌟</h1>
        <p className="welcome-message">
          &#8594; ડિજિટલ બિઝનેસ પ્લાનમાં આપનું સ્વાગત છે! <br />
           ડિજિટલ બિઝનેસ પ્લાનમાં જોડાવા માટે <span className="highlight">માત્ર ₹1100</span> ચૂકવો. આ પ્લાનમાં, તમે 11 નવા સભ્યોને આમંત્રિત કરીને તમારી નેટવર્ક બનાવશો.
        </p>
        <h2 className="how-it-works">✨ કેવી રીતે કાર્ય કરે છે:</h2>
        <ol className="steps">
          <li>
            <strong>🟢 પ્રારંભ કરો:</strong> &#8594; તમે પ્રથમ પગલું તરીકે ડિજિટલ બિઝનેસ પ્લાનમાં જોડાવા માટે ₹1100 નો ચૂકવો.
          </li>
          <li>
            <strong>🔵 11 સભ્યોને આમંત્રણ આપો:</strong> &#8594; પછી, તમારે 11 લોકોને આ પ્લાનમાં જોડવા માટે પ્રેરણા આપવી. દરેક સભ્યને ₹1100 ચૂકવવું પડશે.
          </li>
          <li>
            <strong>🟡 ફાયદો મેળવો:</strong> &#8594; જયારે તમે 11 સભ્યોને સફળતાપૂર્વક જોડાવી લો, ત્યારે તમને ₹2200 પાછા મળશે.
          </li>
        </ol>
        <h3 className="example-title">💡 ઉદાહરણ:</h3>
        <p className="example-description">
          &#8594; માન લો, તમે (અનિલ) ડિજિટલ બિઝનેસ પ્લાનમાં જોડાઈ રહ્યા છો. <br />
          &#8594; તમે 11 મિત્રો અથવા પરિવારજનોને આ પ્લાનમાં જોડાવા માટે આમંત્રણ આપો છો. <br />
          &#8594; જયારે તમારા 11 સભ્યો જોડાય છે, ત્યારે તમે તમારી રોકાણની રકમ ₹1100 ને છોડીને ₹2200 મેળવશો.
        </p>
        <h3 className="total-return">🔙 ટોટલ રિટર્ન:</h3>
        <p>
          &#8594; તમારી રોકાણ: <span className="highlight">₹1100</span> <br />
          &#8594; 11 સભ્ય જોડાય છે: તમારે ₹2200 પાછા મળે છે.
        </p>
        <h3 className="payments-title">💳 Payments:</h3>
        <p>
          &#8594; અમે (ડિજિટલ બિઝનેસ પ્લાન) તમામ ચુકવણીઓ અમારી આફિસિયલ વેબસાઇટ દ્વારા જ લઈએ છીએ. <br />
          &#8594; ઓનલાઇન ચુકવણી માટે, કૃપા કરીને અમારા વેબસાઇટનો ઉપયોગ કરો.
        </p>
        <h3 className="information-title">📢 જાણકારી:</h3>
        <p>
          &#8594; તમારા પૈસા સુરક્ષિત છે અને 100% સિક્યોર છે. માત્ર અમારી આફિસિયલ વેબસાઇટ પર થયેલ ચુકવણીઓ જ માન્ય ગણાશે. અન્ય કોઈપણ વ્યક્તિ દ્વારા ચુકવણી સ્વીકૃત નહિ થાય. <br />
          &#8594; આ તક ગુમાવશો નહીં! આજે જ જોડાઓ અને તમારા નેટવર્કને વિસ્તારો! <br />
          &#8594; ડિજિટલ બિઝનેસ પ્લાન ના ત્રણ ટાસ્ક Complete કરશે તેને 24 TO 48 કલાક માં પેમેન્ટ આપવામાં આવશે.
        </p>

        <h3 className="information-title">📞 Contact no:</h3>
        <p>+91 81280 88393</p>
        <h3 className="information-title">🌐 Our Website:</h3>
        <p>
          &#8594; <a href="http://www.digitalbusinessplan.in" target="_blank" rel="noopener noreferrer">www.digitalbusinessplan.in</a> <br />
          &#8594; <a href="http://www.digitalbusinessgroup.in" target="_blank" rel="noopener noreferrer">www.digitalbusinessgroup.in</a> <br />
          &#8594; <a href="http://www.hi-speedmall.com" target="_blank" rel="noopener noreferrer">www.hi-speedmall.com</a>
        </p>
      </div>
    </Layout>
  );
};

export default Plan;
