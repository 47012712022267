import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaFacebookF, FaYoutube, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import '../../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <Row className="text-center">
          <Col md={2} className="footer-col">
            <h5>Quick Links</h5>
            <Nav className="flex-column quick-links">
              <Nav.Link href="/" className="footer-link">Home</Nav.Link>
              <Nav.Link href="/contact" className="footer-link">Contact</Nav.Link>
              <Nav.Link href="/about" className="footer-link">About</Nav.Link>
              <Nav.Link href="/payment" className="footer-link">Payment</Nav.Link>
            </Nav>
          </Col>
          <Col md={2} className="footer-col">
            <h5>Links</h5>
            <Nav className="flex-column quick-links">
              <Nav.Link href="https://digitalbusinessgroup.in/" className="footer-link">
                Digitalbusinessgroup
              </Nav.Link>
              <Nav.Link href="https://hi-speedmall.com/" className="footer-link">
                Hi-speedmall  
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Contact Us</h5>
            <p className="footer-address">
              461, Opp. Sangna 1 Row House, Shyamdham Chowk, Nana Varachha Surat
            </p>
            <p className="footer-phone"><b>Mo : +91 81280 88393</b></p>
            <div className="social-icons">
              <a href="https://youtube.com/@digitalbusinessplan?si=2HAlfFO3T9bphVdO" className="social-icon"><FaYoutube /></a>
              <a href="https://www.facebook.com/share/oL1p1oyTMFhJoeKo/?mibextid=qi2Omg" className="social-icon"><FaFacebookF /></a>
              <a href="https://www.instagram.com/digital_businessplan.1?igsh=N2w2dWJ0Mm93enY0" className="social-icon"><FaInstagram /></a>
              <a href="https://wa.me/8128088393" className="social-icon"><FaWhatsapp /></a>
            </div>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Newsletter</h5>
            <p>Subscribe to our newsletter for the latest updates and offers.</p>
            <form className="newsletter-form">
              <input type="email" placeholder="Your Email" required />
              <button type="submit" className="btn btn-primary">Subscribe</button>
            </form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p>&copy; {new Date().getFullYear()} Digital Business Plan. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
