import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import "../../styles/User.css"; // Assuming you've a custom CSS file for further styles

function Users() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState('');
    const [currentUser, setCurrentUser] = useState(null);

    const getAllUsers = async () => {
        try {
            const { data } = await axios.get("https://start-keo4.onrender.com/api/v1/auth/get-user");
            setUsers(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    const handleStatusChange = async (id, newStatus) => {
        try {
            const { data } = await axios.patch(`https://start-keo4.onrender.com/api/v1/auth/update-status/${id}`, { status: newStatus });
            setUsers(users.map(user => user._id === id ? data : user));
        } catch (error) {
            console.log(error);
        }
    };

    const handlePhotoClick = (photoPath) => {
        setSelectedPhoto(photoPath);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleEditUser = (user) => {
        setCurrentUser(user);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setCurrentUser(null);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser({ ...currentUser, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.put(`https://start-keo4.onrender.com/api/v1/auth/update-user/${currentUser._id}`, currentUser);
            setUsers(users.map(user => (user._id === currentUser._id ? data : user)));
            handleCloseEditModal();
        } catch (error) {
            console.log(error);
        }
    };


    const [youtubeLink, setYoutubeLink] = useState('');
    const [message, setMessage] = useState('');
    const [existingLink, setExistingLink] = useState(null);

    const handleYouTubeLinkUpload = async (link) => {
        try {
            const response = await axios.post('https://start-keo4.onrender.com/api/youtube/uploadYoutubeLink', { link });
            alert(response.data.message); // Show success message
    
            // Set the uploaded link to existingLink so that it appears in the UI
            setExistingLink(link);
        } catch (error) {
            console.error('Error uploading YouTube link:', error);
            alert('Error: ' + (error.response?.data.message || 'Something went wrong'));
        }
    };
    

const handleExchange = () => {
    if (existingLink) {
        setYoutubeLink(existingLink); // Set input field to the existing link for editing
    } else {
        setYoutubeLink(""); // Clear input if there's no existing link
    }
};

// Example of your form submission handler
const handleSubmit = async (e) => {
    e.preventDefault();
    handleYouTubeLinkUpload(youtubeLink); // Call the upload function
};  

useEffect(() => {
    fetchExistingLink(); // Fetch the stored link on load
}, []);

const fetchExistingLink = async () => {
    try {
        const response = await axios.get('https://start-keo4.onrender.com/api/youtube/getYoutubeLink');
        console.log('Fetched YouTube link:', response.data); // Log fetched link to verify
        if (response.data) {
            setExistingLink(response.data.link);
        }
    } catch (error) {
        console.error('Error fetching YouTube link:', error);
    }
};

    return (
        <Container className="my-5">

            
<Container>
            <div className="youtube-upload-section">
                <form onSubmit={handleSubmit} className="youtube-upload-form">
                    <label htmlFor="youtube-link" className="form-label">Upload YouTube Link:</label>
                    <input
                        type="url"
                        id="youtube-link"
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        className="form-control"
                        placeholder="Enter YouTube link here"
                        required
                    />
                    <button type="submit" className="upload-button">Upload Link</button>
                </form>

                {/* Display the existing link */}
                {existingLink ? (
                <div className="youtube-video-section">
                    <h5>Uploaded YouTube Video:</h5>
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${existingLink.split('v=')[1]}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            ) : (
                <p>No YouTube video uploaded yet.</p>
            )}


                {/* Display feedback messages */}
                {message && (
                    <div className={`alert ${message.includes('successfully') ? 'alert-success' : 'alert-error'}`}>
                        {message}
                    </div>
                )}
            </div>
        </Container>


            <h2 className='text-center mb-4'>All Users List</h2>
            <Table striped bordered hover responsive className="text-center align-middle">
                <thead className="table-dark">
                    <tr>
                        <th>No</th>
                        <th>Photo</th> 
                        <th>First Name</th>
                        <th>Address</th>
                        <th>Aadhaar</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Status</th>
                        <th>Registration Date</th>
                        <th>Aadhaar Photo</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((u, index) => (
                        <tr key={u._id}>
                            <td>{index + 1}</td>
                            <td>
                                {u.photo ? (
                                    <img
                                        src={`https://start-keo4.onrender.com/public/Images/${u?.photo.split('/').pop()}`}
                                        alt={`${u.firstname}'s profile`}
                                        style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/image/Aboutt.jpg'; // Fallback image
                                        }}
                                    />
                                ) : (
                                    <p>No Photo</p>
                                )}
                            </td>
                            <td>{u.firstname}</td>
                            <td>{u.address}</td>
                            <td>{u.aadhaar}</td>
                            <td>{u.email}</td>
                            <td>{u.mobile}</td>
                            <td className={u.status === 'active' ? 'text-primary' : 'text-danger'}>{u.status}</td>
                            <td>{new Date(u.createdAt).toLocaleDateString('en-GB')}</td>
                            <td>
                                {u.aadhaarPhoto ? (
                                    <Button variant="primary" size="sm" onClick={() => handlePhotoClick(u.aadhaarPhoto)}>
                                        View Aadhaar
                                    </Button>
                                ) : (
                                    <p>No Photo</p>
                                )}
                            </td>
                            <td>
                                <Button variant="info" size="sm" className="me-2" onClick={() => handleEditUser(u)}>
                                    Edit
                                </Button>
                                <Form.Control
                                    as="select"
                                    value={u.status}
                                    onChange={(e) => handleStatusChange(u._id, e.target.value)}
                                    className="d-inline w-auto"
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Form.Control>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Aadhaar Photo Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
        <Modal.Title>Aadhaar Photo</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {selectedPhoto ? (
            <img
                src={`https://start-keo4.onrender.com/public/Images/${selectedPhoto.split('/').pop()}`}
                alt="Aadhaar Photo"
                style={{ width: '100%', height: 'auto' }}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/image/Aboutt.jpg'; // Fallback image if Aadhaar photo fails to load
                }}
            />
        ) : (
            <p>No Aadhaar Photo available</p>
        )}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
            Close
        </Button>
    </Modal.Footer>
</Modal>
            {/* Edit User Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentUser && (
                        <Form onSubmit={handleEditSubmit}>
                            <Form.Group controlId="formFirstName" className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstname"
                                    value={currentUser.firstname}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formAddress" className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={currentUser.address}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formAadhaar" className="mb-3">
                                <Form.Label>Aadhaar</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="aadhaar"
                                    value={currentUser.aadhaar}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formPan" className="mb-3">
                                <Form.Label>PAN</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="pan"
                                    value={currentUser.pan}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={currentUser.email}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formMobile" className="mb-3">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={currentUser.mobile}
                                    onChange={handleEditChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formStatus" className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={currentUser.status}
                                    onChange={handleEditChange}
                                    required
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="w-100">
                                Update User
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </Container>
   

    );
}

export default Users;
