import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/Profileupdate.css"

const Profile = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  // state
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [pan, setPan] = useState("");
  const [address, setAddress] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState(""); // current photo path
  const [showPassword, setShowPassword] = useState(false);
  const [aadhaarPhoto, setAadhaarPhoto] = useState(null);
const [aadhaarPhotoPath, setAadhaarPhotoPath] = useState("");

  // Load user data
  useEffect(() => {
    if (auth?.user) {
      const { firstname, email, mobile, address, middlename, lastname, aadhaar, pan, photo } = auth.user;
      setFirstname(firstname);
      setEmail(email);
      setMobile(mobile);
      setAddress(address);
      setMiddlename(middlename);
      setLastname(lastname);
      setAadhaar(aadhaar);
      setPan(pan);
      setProfilePhotoPath(photo); // Set the initial profile photo path
    }
  }, [auth?.user]);

  // Handle profile photo change
  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    const imageUrl = URL.createObjectURL(file); // Preview new image before upload
    setProfilePhotoPath(imageUrl);
  };

  const handleAadhaarPhotoChange = (e) => {
    setAadhaarPhoto(e.target.files[0]);
  };

  // Upload new profile photo
  const uploadProfilePhoto = async () => {
    if (profilePhoto) {
      const formData = new FormData();
      formData.append("file", profilePhoto);
      try {
        const res = await axios.post("https://start-keo4.onrender.com/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setProfilePhotoPath(res?.data?.file?.path);
        toast.success("Profile photo uploaded successfully");
      } catch (err) {
        console.error(err);
        toast.error("Failed to upload profile photo");
      }
    } else {
      toast.error("No photo selected");
    }
  };

  const uploadAadhaarPhoto = async () => {
    if (aadhaarPhoto) {
      const formData = new FormData();
      formData.append("file", aadhaarPhoto);
      try {
        const res = await axios.post("https://start-keo4.onrender.com/uploadAadhaar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setAadhaarPhotoPath(res?.data?.file?.path);
        toast.success("Aadhaar photo uploaded successfully");
      } catch (err) {
        console.error(err);
        toast.error("Failed to upload Aadhaar photo");
      }
    } else {
      toast.error("No photo selected");
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("https://start-keo4.onrender.com/api/v1/auth/profile", {
        firstname,
        email,
        lastname,
        middlename,
        aadhaar,
        pan,
        photo: profilePhotoPath,
        aadhaarPhoto: aadhaarPhotoPath,
        password,
        mobile,
        address,
      });
      if (data?.error) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
        navigate("/dashboard/user");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Your Profile"}>
      <div className="container mt-5" style={{ background: "linear-gradient(135deg, #f0f4f8, #e5e9f0)", minHeight: "100vh" }}>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card p-4" style={{ borderRadius: "20px", backgroundColor: "#ffffff", boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)", padding: "40px" }}>
              <form onSubmit={handleSubmit}>
                <h4 className="mb-4" style={{ fontWeight: "600", textAlign: "center" }}>Update Profile</h4>

                {/* Display current profile photo */}
                <div className="text-center mb-4">
                  {profilePhotoPath ? (
                    <img
                      src={profilePhotoPath.startsWith("blob:") ? profilePhotoPath : `https://start-keo4.onrender.com/public/Images/${profilePhotoPath.split('/').pop()}`}
                      alt="Profile"
                      className="img-fluid"
                      style={{ width: "150px", height: "150px", borderRadius: "50%", border: "4px solid #007bff", objectFit: "cover" }}
                    />
                  ) : (
                    <div className="text-muted">No Photo Available</div>
                  )}
                </div>

                {/* Option to change profile photo */}
                <div className="mb-3">
                  <label htmlFor="profilePhoto" className="form-label">Change Profile Photo</label>
                  <input type="file" onChange={handleProfilePhotoChange} className="form-control" />
                  <button type="button" onClick={uploadProfilePhoto} className="btn btn-secondary mt-2">
                    Upload Photo
                  </button>
                </div>

                {/* Rest of the form fields */}
                {/* First Name */}
                <div className="mb-3">
                  <label htmlFor="firstname" className="form-label">First Name</label>
                  <input type="text" id="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} className="form-control" required />
                </div>

                {/* Middle Name */}
                <div className="mb-3">
                  <label htmlFor="middlename" className="form-label">Middle Name</label>
                  <input type="text" id="middlename" value={middlename} onChange={(e) => setMiddlename(e.target.value)} className="form-control" />
                </div>

                {/* Last Name */}
                <div className="mb-3">
                  <label htmlFor="lastname" className="form-label">Last Name</label>
                  <input type="text" id="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} className="form-control" />
                </div>

                {/* Address */}
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" />
                </div>

                {/* Mobile */}
                <div className="mb-3">
                  <label htmlFor="mobile" className="form-label">Mobile Number</label>
                  <input type="text" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" />
                </div>

                  {/* Aadhar */}
                  <div className="mb-3">
                  <label htmlFor="aadhaar" className="form-label">Aadhar Card</label>
                  <input type="text" id="aadhaar" value={aadhaar} onChange={(e) => setAadhaar(e.target.value)} className="form-control" />
                </div>

                <div className="mb-3">
  <label htmlFor="aadhaarPhoto" className="form-label">Change Aadhaar Photo</label>
  <input type="file" onChange={handleAadhaarPhotoChange} className="form-control" />
  <button type="button" onClick={uploadAadhaarPhoto} className="btn btn-secondary mt-2">
    Change Aadhaar Photo
  </button>
  {aadhaarPhotoPath && (
    <div className="mt-3">
      <img src={`https://start-keo4.onrender.com/${aadhaarPhotoPath}`} alt="Aadhaar" width="100" />
    </div>
  )}
</div>

                {/* Password */}
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type={showPassword ? "text" : "password"} id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                </div>

                {/* Show Password */}
                <div className="mb-3 form-check">
                  <input type="checkbox" className="form-check-input" id="showPassword" onChange={() => setShowPassword(!showPassword)} />
                  <label className="form-check-label" htmlFor="showPassword">Show Password</label>
                </div>

                {/* Submit button */}
                <button type="submit" className="btn btn-primary w-100">Update Profile</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;