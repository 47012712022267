import React, { useEffect, useState } from "react";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import "../../styles/Dashboard.css";

const Dashboard = () => {
  const [auth] = useAuth();
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        const response = await fetch(`https://start-keo4.onrender.com/api/registeredUsers?referenceCode=${auth.user.code}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("Fetched Registered Users:", data);
        setRegisteredUsers(data);
      } catch (error) {
        console.error("Error fetching registered users:", error);
      }
    };
  
    fetchRegisteredUsers();
  }, [auth?.user?.code]);

  return (
    <Layout title={"Dashboard - React App"}>
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="card p-4 shadow-lg rounded-lg mb-4">
              <div className="text-center mb-4">
                {auth?.user?.photo ? (
                  <img
                    src={`https://start-keo4.onrender.com/public/Images/${auth?.user?.photo.split('/').pop()}`}
                    alt="User"
                    className="img-fluid"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      border: "4px solid #007bff",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/image/Aboutt.jpg';
                    }}
                  />
                ) : (
                  <div className="text-muted">No Photo Available</div>
                )}
              </div>
              <div className="text-center mb-4">
                <h4 className="font-weight-bold" style={{ fontSize: "1.8rem", color: "#ff5722" }}>
                  Your Code: {auth?.user?.code || "Not available"}
                </h4>
              </div>
              <div className="text-center">
                <h3 className="mb-4" style={{ fontWeight: "600", color: "#333", fontSize: "1.5rem" }}>User Details</h3>
                <div className="row">
                  <div className="col-6 text-right font-weight-bold" style={{ fontSize: "1.2rem" }}>
                    <p>Firstname:</p>
                    <p>Middlename:</p>
                    <p>Lastname:</p>
                    <p>Address:</p>
                    <p>Aadhaar:</p>
                    <p>PAN:</p>
                    <p>Email:</p>
                    <p>Mobile:</p>
                  </div>
                  <div className="col-6 text-left" style={{ fontSize: "1.2rem" }}>
                    <p>{auth?.user?.firstname || "Not available"}</p>
                    <p>{auth?.user?.middlename || "Not available"}</p>
                    <p>{auth?.user?.lastname || "Not available"}</p>
                    <p>{auth?.user?.address || "Not available"}</p>
                    <p>{auth?.user?.aadhaar || "Not available"}</p>
                    <p>{auth?.user?.pan || "Not available"}</p>
                    <p>{auth?.user?.email || "Not available"}</p>
                    <p>{auth?.user?.mobile || "Not available"}</p>
                  </div>
                </div>
                <button
                  className="btn btn-primary mt-4 px-5 py-2"
                  style={{
                    borderRadius: "25px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: "#007bff",
                    border: "none",
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => navigate("/profile")}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#0056b3"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "#007bff"}
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card p-4 shadow-lg rounded-lg">
              <h3 className="mb-4 text-center" style={{ fontWeight: "600", color: "#333", fontSize: "1.5rem" }}>Registered Users By {auth?.user?.firstname || "Not available"}</h3>
              <table className="table table-striped text-center">
                <thead>
                  <tr style={{ fontSize: "1.2rem", fontWeight: "600" }}> 
                    <th>No</th>
                    <th>Firstname</th>
                    <th>Email</th>
                    <th>Code</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {registeredUsers.length > 0 ? (
                    registeredUsers.map((user, index) => (
                      <tr key={index} style={{ fontSize: "1.2rem" }}> 
                        <td>{index + 1}</td>
                        <td>{user.firstname}</td>
                        <td>{user.email}</td>
                        <td>{user.code}</td>
                        <td>{user.mobile}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center" style={{ fontSize: "1rem" }}>No registered users found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
